import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Img from "gatsby-image"
import { Grid } from "@material-ui/core"
import GalleryItem from "../components/GalleryItem"

export default ({ data, pageContext }) => {
  console.log({ pageContext })
  console.log({ data })
  const { details, images } = data
  const { name, contact, position, email, imageFolder } = details.frontmatter

  const keys = Object.keys(details.frontmatter)

  console.log(keys)

  return (
    <Layout>



      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <div dangerouslySetInnerHTML={{ __html: details.html }} />
        </Grid>
        <Grid container spacing={1} item xs={12} md={6} style={{height: '100%'}}>
          {images.nodes.map(img => {
            const { src } = img.thumbNail.fluid;

            console.log("src", src);
            // const { src:picSrc } = img.picture.fluid;
            // return <img style={{width: '25%'}} key={src} src={src} />
            return (
              <Grid item xs={12} sm={6} key={src}>
                <GalleryItem thumb={img.thumbNail.fluid} picture={img.picture.fixed} />
                {/* <Img fluid={img.childImageSharp.fluid} key={src} /> */}
              </Grid>
            )
          })}

        </Grid>

      </Grid>

      {/*
        {keys.map(key => {
            return <div key={key}>{details.frontmatter[key]}<br /></div>;

        })} */}

      {/* <Img fluid={fluid}/> */}
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!, $imageFolder: String!) {
    details: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        technic
        subject
        size
        room
        price
        position
        name
        minPersons
        maxPersons
        email
        description
        contact
        catring
        imageFolder
      }
    }
    images: allFile(
      filter: {
        sourceInstanceName: { eq: "rooms" }
        extension: { eq: "jpg" }
        relativeDirectory: { eq: $imageFolder }
      }
    ) {
      nodes {
        relativeDirectory
        thumbNail: childImageSharp {
          fluid(maxWidth: 400, maxHeight: 400) {
            ...GatsbyImageSharpFluid
          }
        }
        picture: childImageSharp {
          fixed(width: 800) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`
